import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './steel.css';
import { baseUrl, modal_excel_icon, sorticon } from '../../../api/baseUrl';
import Header from '../../../common/header/header';
import Nav from '../nav';
import Paginate from 'react-paginate';
import LoadingImage from '../../../assets/media/loader.gif';
import { CloseButton, Input, rem } from '@mantine/core';
import { formatMoney } from '../../../GlobalFunctions';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar, IconChevronDown } from '@tabler/icons-react';
import { MultiSelect } from '@mantine/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Steel = () => {
  const [steel, setSteel] = useState([]);
  const [filter, setFilter] = useState([]); //yeh wo hai wo API se aw rahy hai select box ki values hai
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const calendaricon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );
  const icon = (
    <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
  );

  const [brandValue, setBrandValue] = useState([]);
  const [dateValue, setDateValue] = useState();
  const [nameValue, setNameValue] = useState();

  // const [dateValue, setDateValue] = useState();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    //Filter options updated so apply all filters here
    let result = steel;
    if (steel.length && brandValue.length) {
      result = handleFilterBrand(result);
    }
    if (steel.length && dateValue !== undefined) {
      result = handleFilterDate(result);
    }

    if (steel.length && nameValue !== '' && nameValue !== undefined) {
      result = handleFilterName(result);
    }

    setFilteredData(result);
  }, [nameValue, dateValue, brandValue]);

  useEffect(() => {
    setIsLoading(false);
    fetch(`${baseUrl}/steel`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSteel(data.data);
        setFilteredData(data.data);
        setFilter(data.filter);
        setIsLoading(true);
      });
  }, [cookies]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const handleFilterName = (data) => {
    console.log(nameValue);
    if (nameValue !== '' && nameValue !== undefined) {
      var brand_filter = data.filter((item) =>
        item.name.toLowerCase().includes(nameValue.toLowerCase())
      );
    }
    return brand_filter;
  };
  const handleFilterBrand = (data) => {
    if (brandValue.length) {
      var brand_filter = data.filter((item) => brandValue.includes(item.brand));
    }
    return brand_filter;
  };

  const handleFilterDate = (data) => {
    // console.log(dateValue);

    // alphacapitalcom_aof-demo

    // alphacapitalcom_aof-demo

    // tgz3v.}@0OYw

    if (
      dateValue !== undefined &&
      dateValue[0] !== null &&
      dateValue[1] !== null
    ) {
      var sd = dateValue[0];
      var ed = dateValue[1];
      var brand_filter = data.filter((d) => {
        var time = new Date(d.date).getTime();
        return sd <= time && time <= ed;
      });
      return brand_filter;
    }
    return data;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handlePostsPerPage = (e) => {
    setCurrentPage(1);
    setPostsPerPage(e.target.value);
  };

  // const currentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + '-' + mm + '-' + yyyy;
  // document.write(today);
  // };

  return (
    <>
      <Header />
      <div className="analyst cement-main-sec steel-main-section">
        <div className="row omcboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
        {isLoading ? (
          <div className="datatable-container container container_box cement-date-row">
            <div className="excel-btn">
              <div className="row-maintine">
                <div className="select_drop">
                  <select
                    className="selectpicker"
                    value={postsPerPage}
                    onChange={handlePostsPerPage}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              <span style={styles.excel}>
                <ReactHTMLTableToExcel
                  className="download-table-xls-button"
                  table="table"
                  filename={'Steel_' + today}
                  sheet="Steel"
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_excel_icon,
                      }}
                    />
                  }
                />{' '}
              </span>
            </div>
            <table id="table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('date')}>
                    <div className="cement-steel-tbl">
                      Date
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('name')}>
                    <div className="cement-steel-tbl">
                      Name
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('brand')}>
                    <div className="cement-steel-tbl">
                      Brand
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('unit')}>
                    <div className="cement-steel-tbl">
                      Unit
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('low_price')}>
                    <div className="cement-steel-tbl price-tbl-row">
                      Low Price
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('high_price')}>
                    <div className="cement-steel-tbl price-tbl-row">
                      High Price
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('average')}>
                    <div className="cement-steel-tbl price-tbl-row">
                      Average
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                </tr>
                <tr className="second-table-row">
                  <th className="date-sizing">
                    <div>
                      {/* <h1>Form</h1> */}
                      <DatePickerInput
                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                        clearable
                        // leftSection={calendaricon}
                        maxDate={new Date()}
                        type="range"
                        valueFormat="DD MMM, YYYY"
                        // dropdownType="modal"
                        placeholder="Select Date Range"
                        value={dateValue}
                        onChange={setDateValue}
                        mx="auto"
                      />
                    </div>
                  </th>
                  <th>
                    <div>
                      <Input
                        placeholder="Search Name"
                        value={nameValue}
                        onChange={(event) =>
                          setNameValue(event.currentTarget.value)
                        }
                        rightSectionPointerEvents="all"
                        mt="md"
                        rightSection={
                          <CloseButton
                            aria-label="Clear input"
                            onClick={() => setNameValue('')}
                            style={{ display: nameValue ? undefined : 'none' }}
                          />
                        }
                      />
                    </div>
                  </th>
                  <th className="date-sizing">
                    <MultiSelect
                      data={filter.brand}
                      value={brandValue}
                      onChange={setBrandValue}
                      rightSection={icon}
                      placeholder="Select Brand"
                      searchable
                    />
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.length ? (
                  currentPosts.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.name}</td>
                      <td>{item.brand}</td>
                      <td>{item.unit}</td>
                      <td>{formatMoney(item.low_price)}</td>
                      <td>{formatMoney(item.high_price)}</td>
                      <td>{item.average}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="dropdown-pagination">
              <Paginate
                onPageChange={paginate}
                pageCount={Math.ceil(filteredData.length / postsPerPage)}
                previousLabel={'Prev'}
                nextLabel={'Next'}
                containerClassName={'pagination'}
                pageLinkClassName={'page-number'}
                previousLinkClassName={'page-number'}
                nextLinkClassName={'page-number'}
                activeLinkClassName={'active'}
              />
            </div>
          </div>
        ) : (
          <div
            style={{ width: '100%', height: '100%', color: '#426fd7' }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img
              src={LoadingImage}
              style={{ height: '500px' }}
              alt="Loading..."
            />
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
};

export default Steel;

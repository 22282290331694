import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './cement.css';
import { baseUrl, modal_excel_icon, sorticon } from '../../../api/baseUrl';
import Header from '../../../common/header/header';
import Nav from '../nav';
import Paginate from 'react-paginate';
import LoadingImage from '../../../assets/media/loader.gif';
import { rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { MultiSelect, Select } from '@mantine/core';
import { formatMoney } from '../../../GlobalFunctions';
import { IconCalendar } from '@tabler/icons-react';
import { IconChevronDown } from '@tabler/icons-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Cement = () => {
  const [cement, setCement] = useState([]);
  const [filter, setFilter] = useState([]); //yeh wo hai wo API se aw rahy hai select box ki values hai
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [cookies] = useCookies();
  const [brandValue, setBrandValue] = useState([]);
  const [dateValue, setDateValue] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [handleFilterParameter, setHandleFilterParameter] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const icon = (
    <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
  );

  const calendaricon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  useEffect(() => {
    //Filter options updated so apply all filters here
    let result = cement;
    if (cement.length && brandValue.length) {
      // console.log(dateValue);
      result = handleFilterBrand(result);
      // result = handleFilterDate(result);
    }
    if (cement.length && dateValue !== undefined) {
      result = handleFilterDate(result);
    }
    setFilteredData(result);
  }, [dateValue, brandValue]);

  useEffect(() => {
    setIsLoading(false);
    fetch(`${baseUrl}/cement`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCement(data.data);
        setFilteredData(data.data);
        setFilter(data.filter);
        setIsLoading(true);
      });
  }, [cookies]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  /* var filteredData = cement.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  ); */

  // var filteredData =  cement.filter(function(item) {
  // return includes(searchInput.toLowerCase());
  // return item.name == "Ocean";
  // });

  // const setHandleFilterParameter = (value) => {};

  ////////////////////////////////////////////////////////////////
  ////////////////////////////Filter//////////////////////////////
  ////////////////////////////////////////////////////////////////

  const handleFilterBrand = (data) => {
    // fil.brand = value;
    // handleFilter(fil);

    if (brandValue.length) {
      var brand_filter = data.filter((item) => brandValue.includes(item.brand));
    }
    return brand_filter;
  };

  const handleFilterDate = (data) => {
    console.log(dateValue);
    // fil.date = value;
    // handleFilter(fil);

    if (
      dateValue !== undefined &&
      dateValue[0] !== null &&
      dateValue[1] !== null
    ) {
      var sd = dateValue[0];
      var ed = dateValue[1];
      var brand_filter = data.filter((d) => {
        var time = new Date(d.date).getTime();
        return sd <= time && time <= ed;
      });

      return brand_filter;

      // setFilteredData(brand_filter);
      // setFilteredData(brand_filter);
    }
    return data;
  };

  /*const handleFilter = (fil) => {
    console.log(fil);
    if (!fil.brand.length && (fil.date[0] === null || fil.date[1] === null)) {
      setFilteredData(cement);
      return false;
    }
    // var actual_data = cement;
    var brand_filter = [];
    if (fil.date[0] !== null && fil.date[1] !== null) {
      var sd = fil.date[0];
      var ed = fil.date[1];
      brand_filter = brand_filter.filter((d) => {
        var time = new Date(d.date).getTime();
        return sd <= time && time <= ed;
      });
      // setFilteredData(brand_filter);
    }

    // return false;

    if (fil.brand.length) {
      brand_filter = brand_filter.filter((item) =>
        fil.brand.includes(item.brand)
      );
    }
    if (!brand_filter.length) {
      setFilteredData(cement);
    } else {
      setFilteredData(brand_filter);
    }

    return false;
  };*/

  //////////////////////////////////////////////////////////////////
  /////////////////////////End-Filter//////////////////////////////
  ////////////////////////////////////////////////////////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handlePostsPerPage = (e) => {
    setCurrentPage(1);
    setPostsPerPage(e.target.value);
  };

  // const currentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + '-' + mm + '-' + yyyy;
  // document.write(today);
  // };

  return (
    <>
      <Header />
      <div className="analyst cement-main-sec">
        <div className="row omcboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
        {isLoading ? (
          <div className="datatable-container container container_box cement-date-row">
            <div className="excel-btn">
              <div className="row-maintine">
                <div className="select_drop">
                  <select
                    className="selectpicker"
                    value={postsPerPage}
                    onChange={handlePostsPerPage}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
              <span style={styles.excel}>
                <ReactHTMLTableToExcel
                  className="download-table-xls-button"
                  table="table"
                  sheet="Cement"
                  filename={'Cement_' + today}
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_excel_icon,
                      }}
                    />
                  }
                />{' '}
              </span>
            </div>
            <table id="table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('date')}>
                    <div className="cement-steel-tbl">
                      Date
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('brand')}>
                    <div className="cement-steel-tbl">
                      Brand
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('unit')}>
                    <div className="cement-steel-tbl">
                      Unit
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                  <th onClick={() => handleSort('price')}>
                    <div className="cement-steel-tbl price-tbl-row">
                      Price
                      <div
                        className="sorticon"
                        dangerouslySetInnerHTML={{ __html: sorticon }}
                      />
                    </div>
                  </th>
                </tr>
                <tr className="second-table-row">
                  <th className="date-sizing">
                    <div>
                      {/* <h1>Form</h1> */}
                      <DatePickerInput
                        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                        type="range"
                        valueFormat="DD MMM, YYYY"
                        // dropdownType="modal"
                        maxDate={new Date()}
                        placeholder="Select Date Range"
                        value={dateValue}
                        // rightSection={calendaricon}
                        onChange={setDateValue}
                        clearable
                        mx="auto"
                      />
                      {/* <h1 className="date-to-field">To</h1> */}
                      {/*<DatePickerInput
                        placeholder="To"
                        value={todate}
                        onChange={handleFilterToDate}
                        clearable
                      />*/}
                    </div>
                  </th>
                  <th className="date-sizing">
                    <MultiSelect
                      // icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                      data={filter.brand}
                      value={brandValue}
                      onChange={setBrandValue}
                      placeholder="Select Brand"
                      rightSection={icon}
                      searchable
                    />
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.length ? (
                  currentPosts.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.brand}</td>
                      <td>{item.unit}</td>
                      <td>{formatMoney(item.price)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="dropdown-pagination">
              <Paginate
                onPageChange={paginate}
                pageCount={Math.ceil(filteredData.length / postsPerPage)}
                previousLabel={'Prev'}
                nextLabel={'Next'}
                containerClassName={'pagination'}
                pageLinkClassName={'page-number'}
                previousLinkClassName={'page-number'}
                nextLinkClassName={'page-number'}
                activeLinkClassName={'active'}
              />
            </div>
          </div>
        ) : (
          <div
            style={{ width: '100%', height: '100%', color: '#426fd7' }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img
              src={LoadingImage}
              style={{ height: '500px' }}
              alt="Loading..."
            />
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
};

export default Cement;

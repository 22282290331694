import React, { useEffect, useState } from 'react';
import Header from '../../../common/header/header';
import Search2 from '../../media/search2';
import { baseUrl } from '../../../api/baseUrl';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { DatePickerInput } from '@mantine/dates';
import { Button, Modal } from '@mantine/core';
// import YoutubeImage from "../../../assets/media/images/youtube-img.png";
import LoadingImage from '../../../assets/media/loader.gif';
import '../../media/analystbriefing/analystbriefing.css';
import Nav from '../../media/nav';

function AnalystBriefing() {
  const location = useLocation();
  let compId = location.state !== undefined ? location.state.id : 189;
  const [currentPrice, setCurrentPrice] = useState({});
  const [result, setResult] = useState([]);
  const [is, setIs] = useState([]);
  const [cookies] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});
  const [datepicker, setDatePicker] = useState();
  const [visibleItems, setVisibleItems] = useState();
  const [opened, setOpened] = useState(false); // For controlling modal state
  const [currentVideo, setCurrentVideo] = useState(''); // Store the current video link

  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 4);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseUrl}/media/analystbriefing`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${cookies?.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
        setIsLoading(false);
        console.log(data);
      });
  }, []);

  const handleOpenModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setOpened(true);
  };

  return (
    <>
      <Header />
      <div className="result-main analyst">
        <div className="container container_box">
          <Nav companyId={companyId} />
          {/*<div
            className="row resultboxed latest-boxed corporate-briefing-box"
            style={styles.companyname}
          >
            <Search2
              companyId={companyId}
              setCompanyId={setCompanyId}
              setCompany={setCompany}
            />
            <DatePickerInput
              type="range"
              placeholder="Pick dates range"
              value={datepicker}
              onChange={setDatePicker}
            />
            <Button
              variant="gradient"
              gradient={{
                from: 'rgba(29, 66, 131, 1)',
                to: 'rgba(29, 66, 131, 1)',
                deg: 90,
              }}
            >
              Search
            </Button>
          </div>*/}
          {isLoading ? (
            <div className="main-loader d-flex flex-column justify-content-center align-items-center">
              <img
                src={LoadingImage}
                alt="Loading..."
                style={{ height: '500px' }}
              />
            </div>
          ) : (
            <div className="main_rearch">
              <div>
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <div className="res_sec mt-4 corporate-boxes">
                      <div className="row">
                        {result.slice(0, visibleItems).map((item, index) => (
                          <div
                            className="col-lg-3 col-md-4 col-sm-6"
                            key={index}
                          >
                            <div className="items_box">
                              <img
                                src={item.thumbnail}
                                alt=""
                                className="luckbox1_img thumnail-image"
                                onClick={() => handleOpenModal(item.url)}
                                style={{ cursor: 'pointer' }}
                              />
                              <div className="card-body p-0">
                                <ul className="corporate-underlist">
                                  <li>{item.date}</li>
                                </ul>
                                <h5 className="luckheldtext p-0">
                                  {item.title}
                                </h5>
                                <p className="fellowparagraph">
                                  {item.description}
                                </p>
                                {/* <div className="mainlucky d-flex mb-3">
                                  <span className="date_symbol">
                                  {item.date}
                                  </span>
                                </div>
                                 <div className="mainlucky d-flex">
                                  <span className="date_symbol">
                                    {item.date}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {visibleItems < result.length && (
                        <Button onClick={loadMoreItems} className="mt-3 mb-3">
                          Load More
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for video playback */}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="auto"
        centered
      >
        <iframe
          width="560"
          height="315"
          src={currentVideo}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  );
}

const styles = {
  companyname: {
    width: '80%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
  },
};

export default AnalystBriefing;
